
import { Vue, Options } from 'vue-class-component';
import DefaultLayout from '@/lib/layouts/DefaultLayout.vue';
import debounce from 'lodash-es/debounce';
import Calendar from 'dayjs/plugin/calendar';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  BaseIcon,
  BasePagination,
  BaseTextInput,
  DataTable,
  BasePopover,
  SpecialityButton,
  PopoverLink,
  BaseButton
} from '@/lib/components';
import RejectReasonModal from '@/lib/components/Modals/RejectReasonModal.vue';
import {
  Consult,
  PaginatedResponse,
  Patient,
  RejectReasonValue
} from '@/models';
import { ConsultService } from '@/services/api';
import AssignConsultModal from '@/views/worklists/AssignConsultModal.vue';
import {
  getDobFromISOString,
  getNhsNumberFromPatient
} from '@/helpers/patient.helper';
import PopoverButton from '@/lib/components/Popover/PopoverButton.vue';
import { sexOptions, genderOptions } from '@/constants';
import { TranslateResult } from 'vue-i18n/index';
import { IErrors } from '@/lib';
import { useProgressStore } from '@/stores/progress.store';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(localizedFormat);

@Options({
  components: {
    PopoverButton,
    RejectReasonModal,
    BaseIcon,
    AssignConsultModal,
    BaseButton,
    PopoverLink,
    SpecialityButton,
    BasePopover,
    DefaultLayout,
    BasePagination,
    BaseTextInput,
    DataTable
  }
})
export default class WorklistPage extends Vue {
  loading = true;
  progressStore = useProgressStore();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  rows: Consult[] = [];
  perPage = 0;
  total = 0;
  search = '';
  filtered = false;
  consultService = new ConsultService();
  request: CancelTokenSource | null = null;
  selectedConsultId = '';
  enableAssignConsultModal = false;

  enableRejectReasonModal = false;
  sexOptions = sexOptions();

  genderOptions = genderOptions();
  // Reject
  currentConsult: Consult | null = null;
  selectedPatient: Patient | null = null;

  rejectReasonError: string | null = null;

  get page() {
    return Number(this.$route.query.page) || 1;
  }

  get sort() {
    return this.$route.query.sort || 'completed_at';
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  created() {
    this.progressStore.startProgress();
    dayjs.extend(Calendar);
  }

  unmounted() {
    this.progressStore.removeProgress();
    if (this.request) {
      this.request.cancel();
    }
  }

  mounted() {
    this.search = String(this.$route.query.search || '');
    this.fetchConsults();

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchConsults();
        window.scroll({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        unWatchRoute();
      }
    });

    // Re-fetch consults when switching organisation
    this.$watch('organisationId', async () => {
      this.search = '';
      if (Object.keys(this.$route.query).length) {
        await this.$router.replace({ path: this.$route.path });
      }
      await this.fetchConsults();
    });
  }

  async fetchConsults() {
    this.loading = true;

    this.request = axios.CancelToken.source();
    const requestConfig: AxiosRequestConfig = {
      params: {
        include:
          'patient,clinic,clinicConfiguration,review,formSubmission,consultType',
        page: this.page,
        sort: this.sort,
        ...(this.search ? { 'filter[search]': this.search } : {}),
        ...{
          'filter[status]': 'Needs Scheduling',
          'filter[provider]': this.organisationId
        }
      },
      cancelToken: this.request.token
    };

    try {
      const response = (await this.consultService.index(
        requestConfig
      )) as PaginatedResponse<Consult[]>;
      this.request = null;

      // Consults with a Rejected status should not appear in the worklist
      this.rows = response.data
        .filter((consult: Consult) => consult.status !== 'Rejected')
        .map((consult: Consult) => {
          if (consult.patient) {
            consult.patient.nhs = getNhsNumberFromPatient(consult.patient);
          }
          return consult;
        });

      this.perPage = response.meta.per_page;
      this.total = response.meta.total;
      this.filtered = this.search.length > 0;
      this.progressStore.finishProgress();
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.progressStore.setError();
        await this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.consult.fetch-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async updateSearch() {
    // Maintain sort order and only add search param when non-empty
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.$route.query.sort ? { sort: this.$route.query.sort } : {}),
        ...(this.search ? { 'filter[search]': this.search } : {})
      }
    });
  }

  get updateSearchDebounced() {
    return debounce(() => this.updateSearch(), 500);
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  async updateSort(sort: string) {
    // Keep the search if present, but always reset the page
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...(this.search ? { search: this.search } : {}),
        sort
      }
    });
  }

  formatDate(date: string) {
    return this.$d(new Date(date), 'shortMonth');
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'shortMonth');
  }

  getSexOrGender(patient: Patient): string | TranslateResult | undefined {
    if (this.sessionStore.currentOrganisation?.uses_gender && patient.gender) {
      const gender = this.genderOptions.find(
        (option) => option.value === patient.gender
      );
      return gender ? gender.label : patient.gender;
    }
    const sex = this.sexOptions.find((option) => option.value === patient.sex);
    return sex ? sex.label : patient.sex;
  }

  showAssignConsultModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableAssignConsultModal = true;
  }

  closeAssignConsultModal() {
    this.enableAssignConsultModal = false;
    this.selectedConsultId = '';
    this.fetchConsults();
  }

  // Reject consult
  openRejectReasonModal(consult: Consult) {
    this.selectedConsultId = consult.id;
    this.currentConsult = consult;
    this.selectedPatient = consult.patient ? consult.patient : null;
    this.enableRejectReasonModal = true;
  }

  async markAsRejected(rejectedReason: RejectReasonValue) {
    try {
      const data = {
        rejected_at: dayjs().utc().toISOString(),
        rejected_reason_type: rejectedReason.selectedReason,
        rejected_reason: rejectedReason.additionalReason
      };
      await this.consultService.update(this.selectedConsultId, data);
      await this.fetchConsults();
      this.closeRejectReasonModal();
    } catch (e: any) {
      if (e.response.status === 422) {
        this.rejectReasonError = this.extractRejectedErrorMessageFromResponseErrors(e.response.data.errors);
      }
      await this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.consult.update-error')
      });
    }
  }

  closeRejectReasonModal() {
    this.selectedConsultId = '';
    this.currentConsult = null;
    this.selectedPatient = null;
    this.enableRejectReasonModal = false;
    this.fetchConsults();
  }

  updateErrorMessage(value: string) {
    this.rejectReasonError = value;
  }

  extractRejectedErrorMessageFromResponseErrors(
    errors: IErrors
  ): string | null {
    let errorMessages: Array<string> = [];
    if (errors.rejected_reason) {
      errorMessages = errorMessages.concat(errors.rejected_reason);
    }
    if (errors.rejected_reason_type) {
      errorMessages = errorMessages.concat(errors.rejected_reason_type);
    }
    if (errorMessages.length === 0) {
      return null;
    }
    return errorMessages.join(' ');
  }
}
